import React from 'react'
import PropTypes from 'prop-types'
import PromiseLink from './PromiseLink'

import withAdminLinks from '../utils/withAdminLinks';

const FullWidthHeader = ({ title, subtitle, linkTo }) => (
  <div className="FullWidthHeader" style={styles.wrapper}>
    <div className="container" style={styles.container}>
      <div className="row">
        <div className="col-md-8">
          {title && <h1 style={styles.heading}>{title}</h1>}
          {subtitle && <span dangerouslySetInnerHTML={{ __html: subtitle }} />}
        </div>
        <div className="col-md-4">
          {linkTo && <PromiseLink style={styles.button} to={linkTo} />}
        </div>
      </div>
    </div>
  </div>
)

const styles = {
  wrapper: {
    paddingTop: 50,
    paddingBottom: 50,
    backgroundColor: '#83ba09',
    color: 'white',
  },
  container: {
    clear: 'both',
  },
  heading: {
    color: 'white',
  },
  button: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.8,
    lineHeight: '29px',
    paddingTop: 7,
    paddingBottom: 19,
    paddingLeft: 47,
    paddingRight: 47,
    marginTop: 47,
    display: 'block',
    borderColor: '#ffffff',
    borderWidth: 2,
    borderStyle: 'solid',
    float: 'right',
    color: 'white',
  },
  teaser: {},
}

FullWidthHeader.propTypes = {
  title: PropTypes.string,
  teaser: PropTypes.string,
  backgroundUrl: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
}

export default withAdminLinks(FullWidthHeader)

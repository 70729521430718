import React from 'react'
import GatsbyLink from 'gatsby-link'
import classnames from 'classnames'
import _ from 'lodash'

const MARKDOWN_LINK_REGEX = /(\[([^\]]+)\])?(\(([^\)]+)\))?/

const ExternalLink = ({ to, children, className, primary, ...props }) => {
  return (
    <a target="_blank" className={className} href={to} {...props}>
      {children}
    </a>
  )
}

const InternalLink = ({ to, children, className, primary, ...props }) => {
  return (
    <GatsbyLink className={className} to={to} {...props}>
      {children}
    </GatsbyLink>
  )
}

const PromiseLink = ({ to, primary, ...props }) => {
  if (!to) {
    return null
  }
  const match = MARKDOWN_LINK_REGEX.exec(to)
  const linkTo = match && match[4] ? match[4] : to
  const linkText = match && match[2] ? match[2] : null
  const buttonLike = _.get(props, 'buttonLike', true)

  let children = linkText
  if (props.children) {
    children = props.children
  }

  let Component = InternalLink
  if (linkTo.indexOf('http') !== -1) {
    // debugger
    Component = ExternalLink
  }

  return (
    <Component
      className={classnames({
        'primary-button': primary && buttonLike,
      })}
      to={linkTo}
      {...props}
    >
      <span>{children}</span>
    </Component>
  )
}

export default PromiseLink

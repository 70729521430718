import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'

import logo from '../images/logo.png'

import './PageFooter.css'
import { DEFAULT_LANG } from '../utils/constants'
/**
 * TODO: Add a confirmation page to the newsletter.
 */
class PageFooter extends React.Component {
  render() {
    const { language } = this.props;
    const langPrefix = (language === DEFAULT_LANG || !language) ? '' : '/fr'

    const localize = (def, fr) => {
      return langPrefix === "/fr"
        ? fr
        : def
    }

    return (
      <footer className="PageFooter section">
        <a id="contacts" />
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            {/* footer contact */}
            <div className="col-md-6">
              <div className="row footer">
                <div className="col-md-3" style={{ paddingTop: 32 }}>
                  <div className="footer-logo">
                    <Link className="logo" to="/">
                      <img src={logo} />
                    </Link>
                  </div>
                </div>
                <div className="col-md-7">
                  <ul className="footer-contact">
                    <li>
                      <i className="fa fa-map-marker" />
                      <span>
                        3025 Whitfield Ave, <br />
                        <span
                          style={{
                            width: 15,
                            marginRight: 5,
                            display: 'inline-block',
                          }}
                        />
                        Cumming, GA 30040 - USA
                      </span>
                    </li>
                    {/* <li>
                      <i className="fa fa-phone" /> XXX-279-9246
                    </li> */}
                    <li>
                      <i className="fa fa-envelope" />{' '}
                      <a href="#">contact@guinea-promise.org</a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/guineapromise/"
                        target="__blank"
                      >
                        <i className="fa fa-facebook" />{' '}
                        facebook.com/guineapromise
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /footer contact */}
            <div className="col-md-1 spacer" />
            {/* footer newsletter */}
            <div className="col-md-4 newsletter">
              <div className="footer">
                  <h3 className="footer-title">
                    {localize("Newsletter", "Inscription à la Newsletter") }
                  </h3>
                <p>
                {localize(
                  "Subscribe to our newsletter and get the latest updates on our activities and programs.",
                  "Abonnez-vous à notre newsletter et recevez les dernières mises à jour de nos activités et programmes.")
                }
                </p>
                <form
                  name="newsletter"
                  className="footer-newsletter"
                  data-netlify="true"
                >
                  <input
                    className="input"
                    type="email"
                    placeholder={localize("Enter your email", "Entrer votre Email")}
                  />
                  <button type="submit" className="primary-button">
                    {localize("Subscribe", "S'abonner")}
                  </button>
                </form>
              </div>
            </div>
            {/* /footer newsletter */}
          </div>
          {/* /row */}
          {/* footer copyright & nav */}
          <div id="footer-bottom" className="row">
            <div className="col-md-6 col-md-push-6">
              <ul className="footer-nav">
                <li>
                  <Link to={langPrefix + '/'}>
                    {localize("Home", "Accueil")}
                  </Link>
                </li>
                <li>
                  <Link to={langPrefix + '/about-us'}>
                    {localize("About", "Qui sommes nous")}
                  </Link>
                </li>
                <li>
                  <Link to={langPrefix + '/programs'}>
                    {localize("Programs", "Programmes")}
                  </Link>
                </li>
                <li>
                  <a href="#">
                    {localize("Contacts", "Contactez-nous")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* /footer copyright & nav */}
        </div>
        {/* /container */}
      </footer>
    )
  }
}

PageFooter.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

export default PageFooter

import _ from 'lodash'
import moment from 'moment'
const showdown = require('showdown')
const mdConverter = new showdown.Converter({
  ghCodeBlocks: true,
  parseImgDimensions: true,
  tasklists: true,
})

const getExcerpt = node => {
  let excerpt = node.excerpt
  if (node.fields && !_.isEmpty(node.fields.shortExcerpt)) {
    excerpt = node.fields.shortExcerpt
  }

  return excerpt
}

const getEntries = node => {
  const entries = _.get(node, 'frontmatter.entries', [])
  
  if (!entries) {
    return [];
  }

  return entries.map(entry => {
    const excerptHtml = mdConverter.makeHtml(entry.text)
    return {
      title: entry.title,
      subtitle: entry.subtitle,
      shortExcerpt: excerptHtml,
      images: _.get(entry, 'images', []),
      link: entry.link,
      tags: entry.tags,
    }
  })
}

export default (allMarkdownRemark, language = 'en') => {
  if (!allMarkdownRemark) {
    return []
  }
  const mapped = allMarkdownRemark.map(edge => mapSingleMarkdownEdge(edge))

  return _.filter(mapped, ['language', language])
}

export const mapSingleMarkdownEdge = ({ node }) => {
  const dateMoment = moment(_.get(node, 'frontmatter.date'))

  return {
    ...node.frontmatter,
    entries: getEntries(node),
    html: node.html,
    images: _.get(node, 'frontmatter.images', undefined),
    language: _.get(node, 'frontmatter.language', undefined),
    slug: _.get(node, 'fields.slug', undefined),
    excerpt: getExcerpt(node),
    formattedDate: dateMoment.isValid()
      ? dateMoment.format('MMMM Do YYYY')
      : undefined,
  }
}

export const findFirstEntryWithTags = (mappedNode, tags = []) => {
  return _.find(
    _.get(mappedNode, 'entries'),
    entry => _.difference(tags, entry.tags).length === 0
  )
}

export const findAllEntriesWithTags = (mappedNode, tags = []) => {
  return _.filter(
    _.get(mappedNode, 'entries'),
    entry => _.difference(tags, entry.tags).length === 0
  )
}

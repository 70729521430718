import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import withAdminLinks from '../utils/withAdminLinks';

import './PageFooter.css'

class CenteredParagraph extends React.Component {
  render() {
    const { subtitle, blockStyle, title, content } = this.props
    return (
      <div
        className="CenteredParagraph section"
        style={_.assign({}, blockStyle)}
      >
        {/* container */}
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="section-title text-center">
                <h2 className="title">{title}</h2>
                {subtitle && (
                  <p
                    className="sub-title"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                  />
                )}
              </div>
            </div>

            <div className="col-md-8 col-md-offset-2">
              <div className="section-content text-center">
                <p
                  className="sub-title"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </div>
          </div>
          {/* /row */}
        </div>
        {/* /container */}
      </div>
    )
  }
}

CenteredParagraph.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  blockStyle: PropTypes.object,
}

export default withAdminLinks(CenteredParagraph)

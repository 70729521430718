import React from 'react'

import withAdminLinks from '../utils/withAdminLinks';

const SingleValue = ({ value }) => {
  return (
    <div className="col-md-6">
      <div className="article">
        <div className="article-content" style={{ marginTop: 0 }}>
          <h3 className="article-title">{value.title}</h3>
          <p>{value.subtitle}</p>
        </div>
      </div>
    </div>
  )
}

const OurValuesBlock = ({ title, subtitle, values }) => {
  return (
    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="section-title text-center">
              <h2 className="title">{title}</h2>
              <p className="sub-title" style={{ marginBottom: 32 }}>
                {subtitle}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <div className="row">
              {values.map((value, index) => (
                <SingleValue value={value} key={`${index}`} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withAdminLinks(OurValuesBlock)

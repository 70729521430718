import React from 'react'
import _ from 'lodash'
import { Link, StaticQuery } from 'gatsby'

import Layout from '../components/layout'
import FullWidthHeader from '../components/FullWidthHeader'
import PageFooter from '../components/PageFooter'

import mapMarkdownRemarks from '../components/mapMarkdownRemarks'
import CenteredParagraph from '../components/CenteredParagraph'
import OurValuesBlock from '../components/OurValuesBlock'

const AboutUs = ({ ourMission, ourCommitment, ourStory, ourValues, language }) => {
  return (
    <Layout language={language}>
      <CenteredParagraph
        node={ourMission}
        title={ourMission.title}
        content={ourMission.html}
        subtitle={ourMission.subtitle}
        blockStyle={{ backgroundColor: '#f4f4f8' }}
      />
      {ourStory && (
        <CenteredParagraph
          node={ourStory}
          title={ourStory.title}
          content={ourStory.html}
          subtitle={ourStory.subtitle}
          blockStyle={{}}
        />
      )}
      <OurValuesBlock
        node={ourValues}
        title={ourValues.title}
        subtitle={ourValues.subtitle}
        values={ourValues.entries}
      />
      <FullWidthHeader
        node={ourCommitment}
        title={ourCommitment.title}
        subtitle={ourCommitment.subtitle}
        linkTo={ourCommitment.link}
      />
      <PageFooter language={language}/>
    </Layout>
  )
}

const AboutUsQuery = ({ pageContext }) => (
  <StaticQuery
    query={graphql`
      query {
        ourMission: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#about-us-mission" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }

        ourStory: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#about-us-story" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }

        ourValues: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#about-us-values" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }

        ourCommitment: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#homepage-our-commitment" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
      }
    `}
    render={data => {
      const language = _.get(pageContext, 'language')

      const ourMission = _.first(mapMarkdownRemarks(data.ourMission.edges || [], language))
      const ourCommitment = _.first(mapMarkdownRemarks(
        data.ourCommitment.edges || [],
        language
      ))
      const ourStory = _.first(
        mapMarkdownRemarks(data.ourStory.edges || [], language)
      )
      const ourValues = _.first(mapMarkdownRemarks(data.ourValues.edges || [], language))

      return (
        <AboutUs
          language={language}
          ourMission={ourMission}
          ourCommitment={ourCommitment}
          ourStory={ourStory}
          ourValues={ourValues}
        />
      )
    }}
  />
)

export default AboutUsQuery
